import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { generateClient } from 'aws-amplify/api';
import { createArticles, createParagraph, deleteParagraph, updateArticles, updateParagraph } from '../../graphql/mutations';

export default function EditModal({ isOpen, onClose, onSubmit, article }) {
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'paragraphs',
    });
    const [submissionError, setSubmissionError] = useState(null);
    const [loading, setLoading] = useState(false);
    const client = generateClient();
    const TopTabArray = ["Actualité", "nouveauté", "Portrait", "Chronique", "Agenda"];

    useEffect(() => {
        if (article) {
            setValue('title', article.Titles);
            setValue('rubrique', article.rubrique);
            setValue('imageLink', article.images);
            setValue('paragraphs', article.Paragraphs?.items?.map(p => p.text) || []);
        }
    }, [article, setValue]);

    const onSubmitForm = async (data) => {
        setLoading(true);
        try {
            const updatedArticle = {
                id: article.id,
                Titles: data.title,
                rubrique: data.rubrique,
                images: data.imageLink,
            };
            
            const updateResponse = await client.graphql({
                query: updateArticles,
                variables: { input: updatedArticle },
            });
    
            const existingParagraphs = article?.Paragraphs?.items || [];
            const updatedParagraphs = [...existingParagraphs];
            
            for (const [index, text] of data.paragraphs.entries()) {
                const existingParagraph = existingParagraphs[index];
                const paragraphData = {
                    text,
                    articlesID: updateResponse.data.updateArticles.id,
                };
                
                if (existingParagraph) {
                    if (!text) {
                        await client.graphql({
                            query: deleteParagraph,
                            variables: { input: { id: existingParagraph.id } },
                        });
                        updatedParagraphs.splice(index, 1);
                    } else {
                        paragraphData.id = existingParagraph.id;
                        await client.graphql({
                            query: updateParagraph,
                            variables: { input: paragraphData },
                        });
                        updatedParagraphs[index].text = text;
                    }
                } else if (text) {
                    const createResponse = await client.graphql({
                        query: createParagraph,
                        variables: { input: paragraphData },
                    });
                    updatedParagraphs.push(createResponse.data.createParagraph);
                }
            }
    
            onSubmit({ ...updateResponse.data.updateArticles, Paragraphs: { items: updatedParagraphs } });
            onClose();
        } catch (error) {
            console.error("An error occurred while updating the article:", error);
            setSubmissionError('An error occurred while updating the article.');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-4">Edit Article</h2>
                {submissionError && <p className="text-red-500 mb-4">{submissionError}</p>}
                
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            id="title"
                            className="w-full mt-1 p-2 border rounded"
                            type="text"
                            placeholder="Title"
                            {...register('title', { required: 'Title is required' })}
                        />
                        {errors.title && <p className="text-red-500">{errors.title.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="rubrique" className="block text-sm font-medium text-gray-700">Rubrique</label>
                        <select
                            id="rubrique"
                            className="w-full mt-1 p-2 border rounded"
                            {...register('rubrique', { required: 'Rubrique is required' })}
                        >
                            <option value="">Select Rubrique</option>
                            {TopTabArray.map((rubrique, index) => (
                                <option key={index} value={rubrique}>
                                    {rubrique}
                                </option>
                            ))}
                        </select>
                        {errors.rubrique && <p className="text-red-500">{errors.rubrique.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="imageLink" className="block text-sm font-medium text-gray-700">Image Link</label>
                        <input
                            id="imageLink"
                            className="w-full mt-1 p-2 border rounded"
                            type="text"
                            placeholder="Image Link"
                            {...register('imageLink')}
                        />
                    </div>

                    {fields.map((field, index) => (
                        <div key={field.id} className="mb-4">
                            <label htmlFor={`paragraphs.${index}`} className="block text-sm font-medium text-gray-700">Paragraph {index + 1}</label>
                            <textarea
                                id={`paragraphs.${index}`}
                                {...register(`paragraphs.${index}`)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                rows={4}
                            />
                        </div>
                    ))}

                    <div className="flex justify-between mb-4">
                        <button
                            type="button"
                            className="bg-green-500 text-white px-4 py-2 rounded"
                            onClick={() => append('')}
                        >
                            Add Paragraph
                        </button>
                        <button
                            type="button"
                            className="bg-red-500 text-white px-4 py-2 rounded"
                            onClick={() => remove(fields.length - 1)}
                            disabled={fields.length === 1}
                        >
                            Remove Last Paragraph
                        </button>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 ${loading ? 'cursor-wait' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="ml-2 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
