import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { createAgenda } from '../../graphql/mutations'; // Ensure this mutation is correctly defined
import { generateClient } from 'aws-amplify/api'; // Ensure generateClient is the correct function

export default function AddAgendaModal({ isOpen, onClose, setAgendas }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(null);
  const client = generateClient();

  useEffect(() => {
    if (isOpen) {
      setError(null); // Clear error when modal opens
    }
  }, [isOpen]);

  const onSubmitForm = async (data) => {
    const newAgenda = {
      name: data.name,
      date: data.date,
      description: data.note, 
    };      


    try {
      const createAgendaResponse = await client.graphql({
        query: createAgenda,
        variables: { input: newAgenda },
      });
      setAgendas((prevAgendas) => [...prevAgendas, createAgendaResponse.data.createAgenda]);
      onClose(); // Close modal after successful submission
    } catch (error) {
      setError("Erreur lors de la création de l'agenda: " + error.message);
      console.error("Erreur lors de la création de l'agenda :", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-96">
        <h2 className="text-2xl mb-4">Create New Agenda</h2>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="mb-4">
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Agenda Name"
              {...register('name', { required: 'Name is required' })}
            />
            {errors.name && <p className="text-red-500">{errors.name.message}</p>}
          </div>

          <div className="mb-4">
            <input
              className="w-full p-2 border rounded"
              type="date"
              {...register('date', { required: 'Date is required' })}
            />
            {errors.date && <p className="text-red-500">{errors.date.message}</p>}
          </div>

          <div className="mb-4">
            <textarea
              className="w-full p-2 border rounded"
              placeholder="Agenda Description"
              {...register('note')} // Match 'note' with 'description'
            ></textarea>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <div className="flex justify-between">
            <button type="button" className="bg-gray-300 text-black p-2 rounded" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
