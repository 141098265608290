import React from 'react';
import searchIcon from "../../assets/img/Search.png";
import { Link } from 'react-router-dom';

export default function Header({ pageName, isAdmin, search, setSearch }) {
  return (
    <header className='w-full flex flex-col items-center bg-white shadow-md py-4'>
      <div className='w-11/12 border border-slate-300 h-12 rounded-full flex items-center px-4 mt-5 shadow-sm'>
        <img 
          src={searchIcon} 
          alt="Search Icon" 
          className='mr-3 w-5 h-5 opacity-70' 
          role="img" 
        />
        <input
          className='outline-none h-full w-full bg-transparent text-gray-700 placeholder-gray-400'
          placeholder='Rechercher un article'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          aria-label="Search articles"
        />
      </div>

      <nav className="w-full mt-6">
        <div className="w-full flex justify-center overflow-x-auto whitespace-nowrap space-x-6">
          <Link 
            to='/editorMasterRaceLinkPro' 
            state={{ pageName: 'articles' }} 
            className={`text-lg transition-colors duration-300 ${
              pageName === 'articles' 
                ? "font-extrabold text-blue-600" 
                : "font-medium text-gray-600"
            } hover:text-blue-600`}
            aria-current={pageName === 'articles' ? "page" : undefined}
          >
            Articles
          </Link>

          <Link 
            to='/editorMasterRaceLinkPro-EditCover' 
            state={{ pageName: 'cover' }} 
            className={`text-lg transition-colors duration-300 ${
              pageName === 'cover' 
                ? "font-extrabold text-blue-600" 
                : "font-medium text-gray-600"
            } hover:text-blue-600`}
            aria-current={pageName === 'cover' ? "page" : undefined}
          >
            Cover
          </Link>

          {isAdmin && (
            <>
              <Link 
                to='/message-master-ace' 
                state={{ pageName: 'Messages' }} 
                className={`text-lg transition-colors duration-300 ${
                  pageName === 'Messages' 
                    ? "font-extrabold text-blue-600" 
                    : "font-medium text-gray-600"
                } hover:text-blue-600`}
                aria-current={pageName === 'Messages' ? "page" : undefined}
              >
                Messages
              </Link>

              <Link 
                to='/agenda-master-ace-agenda-journal' 
                state={{ pageName: 'Agenda' }} 
                className={`text-lg transition-colors duration-300 ${
                  pageName === 'Agenda' 
                    ? "font-extrabold text-blue-600" 
                    : "font-medium text-gray-600"
                } hover:text-blue-600`}
                aria-current={pageName === 'Agenda' ? "page" : undefined}
              >
                Agenda
              </Link>

              <Link 
                to='/adminPage' 
                state={{ pageName: 'adminPage' }} 
                className={`text-lg transition-colors duration-300 ${
                  pageName === 'adminPage' 
                    ? "font-extrabold text-blue-600" 
                    : "font-medium text-gray-600"
                } hover:text-blue-600`}
                aria-current={pageName === 'adminPage' ? "page" : undefined}
              >
                Admin
              </Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}