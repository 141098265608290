import React, { useState, useEffect } from 'react';

export default function UserModal({ user, isOpen, onClose, onSave }) {
  const [name, setName] = useState(user?.name || '');
  const [isAdmin, setIsAdmin] = useState(user?.admin || false);
  const [isEditor, setIsEditor] = useState(user?.editor || false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setIsAdmin(user.admin);
      setIsEditor(user.editor);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ id: user.id, name, admin: isAdmin, editor: isEditor });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Admin</label>
            <input
              type="checkbox"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              className="mr-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Editor</label>
            <input
              type="checkbox"
              checked={isEditor}
              onChange={(e) => setIsEditor(e.target.checked)}
              className="mr-2"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
