import React from "react";
import Footers from "../Components/Footers";
import Header from "../Components/Header";

export default function ProposPage() {
  return (
    <main className="flex flex-col min-h-screen">
      {/* Header */}
      <Header />

      {/* Content */}
      <div className="flex-grow container mx-auto px-4 py-10">
        <h1 className="text-4xl font-bold text-center mb-6">À PROPOS</h1>
        <p className="text-lg text-gray-700 text-center mb-4">
          La lecture JE contribue est le tout premier site d'actualité littéraire africaine au Cameroun. Nous sommes spécialisés dans la collecte, le traitement et la diffusion de l’information et de la communication littéraire africaine en Afrique pour l’Afrique et pour le reste du monde.
        </p>
      </div>

      {/* Footer */}
      <Footers />
    </main>
  );
}