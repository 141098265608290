import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { generateClient } from 'aws-amplify/api';
import { updateAgenda } from '../../graphql/mutations'; // Ensure this is correctly imported

export default function EditModal({ isOpen, onClose, agenda, setAgendas }) {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const client = generateClient();

  useEffect(() => {
    if (agenda) {
      setValue('name', agenda.name);
      setValue('date', agenda.date);
      setValue('description', agenda.description); // Changed 'note' to 'description'
    }
  }, [agenda, setValue]);

  const onSubmitForm = async (data) => {
    const updatedAgenda = {
      ...agenda,
      name: data.name,
      date: data.date,
      description: data.description, // Updated to match GraphQL field
    };

    try {
      const updateAgendaResponse = await client.graphql({
        query: updateAgenda,
        variables: { input: updatedAgenda },
      });
      setAgendas((prevAgendas) => 
        prevAgendas.map((a) => 
          a.id === updatedAgenda.id ? updateAgendaResponse.data.updateAgenda : a
        )
      );
      onClose(); // Close modal after successful submission
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'agenda :", error);
      // Handle the error accordingly
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-96">
        <h2 className="text-2xl mb-4">Edit Agenda</h2>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="mb-4">
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Agenda Name"
              {...register('name', { required: 'Name is required' })}
            />
            {errors.name && <p className="text-red-500">{errors.name.message}</p>}
          </div>

          <div className="mb-4">
            <input
              className="w-full p-2 border rounded"
              type="date"
              {...register('date', { required: 'Date is required' })}
            />
            {errors.date && <p className="text-red-500">{errors.date.message}</p>}
          </div>

          <div className="mb-4">
            <textarea
              className="w-full p-2 border rounded"
              placeholder="Agenda Description"
              {...register('description')} // Updated to 'description' from 'note'
            ></textarea>
          </div>

          <div className="flex justify-between">
            <button type="button" className="bg-gray-300 text-black p-2 rounded" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
