import React from 'react'
import { useLocation } from 'react-router-dom'

import Header from '../components/header';
import EditCard from '../components/EditCard';

export default function Rubrique() {
  const location = useLocation();
  const pageName = location.state?.pageName
  return (
    <main>
      <Header pageName={pageName}/>
      <h1 className='text-left text-4xl font-extrabold m-5 mt-10'>{pageName}</h1>
      <div className='p-2 w-full max-w-2xl mt-1'>
        <EditCard/>
        <EditCard/>
        <EditCard/>

      </div>

      {/* Footer */}

      
    </main>    
)
}
