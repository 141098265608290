/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      mail
      nom
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mail
        nom
        note
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgenda = /* GraphQL */ `
  query GetAgenda($id: ID!) {
    getAgenda(id: $id) {
      id
      name
      date
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgenda = /* GraphQL */ `
  query ListAgenda(
    $filter: ModelAgendaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgenda(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        date
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRubrique = /* GraphQL */ `
  query GetRubrique($id: ID!) {
    getRubrique(id: $id) {
      id
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRubriques = /* GraphQL */ `
  query ListRubriques(
    $filter: ModelRubriqueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubriques(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCover = /* GraphQL */ `
  query GetCover($id: ID!) {
    getCover(id: $id) {
      id
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCovers = /* GraphQL */ `
  query ListCovers(
    $filter: ModelCoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCovers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getParagraph = /* GraphQL */ `
  query GetParagraph($id: ID!) {
    getParagraph(id: $id) {
      id
      text
      title
      articlesID
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listParagraphs = /* GraphQL */ `
  query ListParagraphs(
    $filter: ModelParagraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParagraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        title
        articlesID
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paragraphsByArticlesID = /* GraphQL */ `
  query ParagraphsByArticlesID(
    $articlesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelParagraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paragraphsByArticlesID(
      articlesID: $articlesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        title
        articlesID
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUSER = /* GraphQL */ `
  query GetUSER($id: ID!) {
    getUSER(id: $id) {
      id
      name
      editor
      Articles {
        nextToken
        __typename
      }
      admin
      logid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUSERS = /* GraphQL */ `
  query ListUSERS(
    $filter: ModelUSERFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUSERS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        editor
        admin
        logid
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Titles
        images
        userID
        rubrique
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articlesByUserID = /* GraphQL */ `
  query ArticlesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Titles
        images
        userID
        rubrique
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
