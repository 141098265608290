import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import Header from '../Components/Header';
import Footers from '../Components/Footers';
import { Link } from 'react-router-dom';


export default function ArticlePage() {
  const location = useLocation();
  const articleID = location.state?.articleID; // Ensure articleID is passed from the previous page
  const [article, setArticle] = useState(null); // State to hold the fetched article
  const [loading, setLoading] = useState(true); // State to handle loading state
  const client = generateClient();

  useEffect(() => {
    const fetchArticle = async () => {
      try {

        const result = await client.graphql({
            query: getArticles,
            variables: {
                id: articleID,
            }
          });
        setArticle(result.data.getArticles);
        setLoading(false); // Set loading to false once the article is fetched
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    if (articleID) {
      fetchArticle();
    }
  }, [articleID]);

  if (loading) {
    return <p>Loading...</p>; // Loading state while fetching the article
  }

  if (!article) {
    return <p>Article not found.</p>; // Message if no article is found
  }

  return (
    <main className="pl-2 pr-2">
        <Header/>
        <img src={article.images} alt={article.Titles} className="w-full h-72 mt-2 rounded-xl" />
        <h1 className="text-4xl font-bold mt-4">{article.Titles}</h1>
        <div className="mt-4 ">
            {article.Paragraphs && article.Paragraphs.items.map((paragraph, index) => (
            <div key={index} className="mb-4">
                <p className="text-lg">{paragraph.text}</p>
            </div>
            ))}
        </div>


      {/* Footer */}
      <div className='w-full'>
        <footer className="bg-black text-white py-4 w-full  text-center">
          <div className="container mx-auto text-center">
            <p className="text-lg font-semibold">lalecturejecontribue</p>
            <p className="text-sm mt-1">© 2024 lalecturejecontribue. All rights reserved.</p>
            <div className="mt-4">
              <Link to="/Apropos" className="text-white mx-2 hover:underline">À propos</Link>
              <Link to="/contact" className="text-white mx-2 hover:underline">Contact</Link>
            </div>
            <div className="mt-4 flex justify-center space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </footer>

      </div>


      </main>
  );
}


export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
           items {
            id
            order
            text
            title
          }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;