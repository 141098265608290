import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import { useLocation } from 'react-router-dom';
import Card from '../Components/Card';
import Footers from '../Components/Footers';
import { generateClient } from 'aws-amplify/api';
import { listArticles } from '../graphql/queries';

export default function Rubrique() {
  const location = useLocation();
  const pageName = location.state?.pageName;
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const client = generateClient();

  const getArticles = async () => {
    try {
      const listArticlesResponse = await client.graphql({
        query: listArticles,
      });
      const allArticles = listArticlesResponse.data.listArticles.items;
      setArticles(allArticles);

      // Filter articles by rubrique (category) matching the pageName
      const rubriqueArticles = allArticles.filter(article => article.rubrique === pageName);
      setFilteredArticles(rubriqueArticles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <main>
      <Header pageName={pageName} />
      <h1 className='text-left text-4xl font-extrabold m-5 mt-10'>{pageName}</h1>
      <div className='p-2 w-full max-w-2xl mt-1'>
        {filteredArticles.map(article => (
          <Card key={article.id} article={article} />
        ))}
      </div>
      {/* Footer */}
      <Footers />
    </main>
  );
}
