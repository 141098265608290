/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      mail
      nom
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      mail
      nom
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      mail
      nom
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgenda = /* GraphQL */ `
  mutation CreateAgenda(
    $input: CreateAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    createAgenda(input: $input, condition: $condition) {
      id
      name
      date
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgenda = /* GraphQL */ `
  mutation UpdateAgenda(
    $input: UpdateAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    updateAgenda(input: $input, condition: $condition) {
      id
      name
      date
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgenda = /* GraphQL */ `
  mutation DeleteAgenda(
    $input: DeleteAgendaInput!
    $condition: ModelAgendaConditionInput
  ) {
    deleteAgenda(input: $input, condition: $condition) {
      id
      name
      date
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRubrique = /* GraphQL */ `
  mutation CreateRubrique(
    $input: CreateRubriqueInput!
    $condition: ModelRubriqueConditionInput
  ) {
    createRubrique(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRubrique = /* GraphQL */ `
  mutation UpdateRubrique(
    $input: UpdateRubriqueInput!
    $condition: ModelRubriqueConditionInput
  ) {
    updateRubrique(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRubrique = /* GraphQL */ `
  mutation DeleteRubrique(
    $input: DeleteRubriqueInput!
    $condition: ModelRubriqueConditionInput
  ) {
    deleteRubrique(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCover = /* GraphQL */ `
  mutation CreateCover(
    $input: CreateCoverInput!
    $condition: ModelCoverConditionInput
  ) {
    createCover(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCover = /* GraphQL */ `
  mutation UpdateCover(
    $input: UpdateCoverInput!
    $condition: ModelCoverConditionInput
  ) {
    updateCover(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCover = /* GraphQL */ `
  mutation DeleteCover(
    $input: DeleteCoverInput!
    $condition: ModelCoverConditionInput
  ) {
    deleteCover(input: $input, condition: $condition) {
      id
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createParagraph = /* GraphQL */ `
  mutation CreateParagraph(
    $input: CreateParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    createParagraph(input: $input, condition: $condition) {
      id
      text
      title
      articlesID
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateParagraph = /* GraphQL */ `
  mutation UpdateParagraph(
    $input: UpdateParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    updateParagraph(input: $input, condition: $condition) {
      id
      text
      title
      articlesID
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteParagraph = /* GraphQL */ `
  mutation DeleteParagraph(
    $input: DeleteParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    deleteParagraph(input: $input, condition: $condition) {
      id
      text
      title
      articlesID
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUSER = /* GraphQL */ `
  mutation CreateUSER(
    $input: CreateUSERInput!
    $condition: ModelUSERConditionInput
  ) {
    createUSER(input: $input, condition: $condition) {
      id
      name
      editor
      Articles {
        nextToken
        __typename
      }
      admin
      logid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUSER = /* GraphQL */ `
  mutation UpdateUSER(
    $input: UpdateUSERInput!
    $condition: ModelUSERConditionInput
  ) {
    updateUSER(input: $input, condition: $condition) {
      id
      name
      editor
      Articles {
        nextToken
        __typename
      }
      admin
      logid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUSER = /* GraphQL */ `
  mutation DeleteUSER(
    $input: DeleteUSERInput!
    $condition: ModelUSERConditionInput
  ) {
    deleteUSER(input: $input, condition: $condition) {
      id
      name
      editor
      Articles {
        nextToken
        __typename
      }
      admin
      logid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticles = /* GraphQL */ `
  mutation UpdateArticles(
    $input: UpdateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    updateArticles(input: $input, condition: $condition) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticles = /* GraphQL */ `
  mutation DeleteArticles(
    $input: DeleteArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    deleteArticles(input: $input, condition: $condition) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
