import React from 'react';
import cover from "../assets/img/cardCover.jpeg";
import { Link } from 'react-router-dom';

export default function Card({article}) {
  // Convert the ISO date string to a Date object
  const dateObj = new Date(article.createdAt);

  // Format the date to the desired format (e.g., "Jul 12, 2024")
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', options);

  return (
    <Link to="/article" state={{ articleID: article.id }} >
        <div className='flex justify-between h-36 w-full p-4 items-center'>
          <img src={article.images} alt="Cover" className='h-full w-1/3 rounded-lg object-cover' />
          <div className='w-2/3  pl-4 flex flex-col justify-between'>
            <p className='text-base text-gray-400 font-normal'>{article.rubrique}</p>
            <p className='text-[21px] font-semibold leading-tight mt-2'>{article.Titles}</p>
            <p className='text-base text-gray-400 font-normal mt-2'>{formattedDate}</p>
          </div>
        </div>
    </Link>
 
  );
}
