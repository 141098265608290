import React, { useState, useEffect } from 'react';
import { listUSERS } from '../../graphql/queries';
import { updateUSER } from '../../graphql/mutations';
import Header from '../components/header';
import UserModal from '../components/UserModal';
import { generateClient } from 'aws-amplify/api';

export default function AdminPage() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const client = generateClient();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await client.graphql({ query: listUSERS });
        setUsers(userData.data.listUSERS.items);
      } catch (err) {
        setError(err);
        console.error("Error fetching users:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleSave = async (updatedUser) => {
    try {
      await client.graphql({
        query: updateUSER,
        variables: {
          input: updatedUser,
        },
      });
      setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
      setIsModalOpen(false);
    } catch (err) {
      console.error("Error updating user:", err);
      setError(err);
    }
  };

  if (loading) return <p className="text-center mt-8">Loading...</p>;
  if (error) return <p className="text-center text-red-500 mt-8">Error fetching users: {error.message}</p>;

  return (
    <main className=" bg-gray-100 min-h-screen">
      <Header pageName={"Admin"} isAdmin={true}/>
      <h1 className="text-3xl font-bold mb-6">Admin Page</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="text-left p-4">Name</th>
              <th className="text-left p-4">Role</th>
              <th className="text-left p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} className="border-t">
                <td className="p-4">{user.name}</td>
                <td className="p-4">{user.admin ? 'Admin' : user.editor ? 'Editor' : 'User'}</td>
                <td className="p-4">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => handleUserClick(user)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <UserModal
        user={selectedUser}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
      />
    </main>
  );
}
