import React from 'react';
import cover from "../../assets/img/cardCover.jpeg";
import edit from "../../assets/img/Edit.png";
import del from "../../assets/img/del.png";

export default function EditCard({ article, onEditClick, onDeleteClick }) { // Added onDeleteClick
  // Convert the ISO date string to a Date object
  const dateObj = new Date(article.createdAt);

  // Format the date to the desired format (e.g., "Jul 12, 2024")
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', options);

  return (
    <div className='flex justify-between h-36 w-full p-2 items-center'>
      <img src={cover} alt="Cover" className='h-full w-1/3 rounded-lg object-cover' />
      <div className='w-2/3 pl-4 flex flex-col justify-between'>
        <p className='text-base text-gray-400 font-normal'>{article.rubrique}</p>
        <p className='text-[21px] font-semibold leading-tight mt-2'>{article.Titles}</p> {/* Updated field name */}
        <div className='flex justify-between w-full mt-2'>
          <p className='text-base text-gray-400 font-normal'>{formattedDate}</p>     
          <div className='flex justify-between w-16 h-6'>
            <img src={edit} alt="edit icon" className='w-6 h-6' onClick={onEditClick} /> {/* Edit click handler */}
            <img src={del} alt="delete icon" className='w-6 h-6' onClick={onDeleteClick} /> {/* Delete click handler */}
          </div>
        </div>
      </div>
    </div>
  );
}
