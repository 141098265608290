import React, { useState } from 'react';
import Footers from "../Components/Footers";
import Header from "../Components/Header";
import { useForm } from "react-hook-form";
import { generateClient } from '@aws-amplify/api'; // Import the client generation function

const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      mail
      nom
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;

export default function ContactPage() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Initialize client
  const client = generateClient(); 

  const onSubmit = async (data) => {
    try {
      const input = {
        mail: data.mail,
        nom: data.name,
        note: data.message,
      };

      // Use the client to perform the GraphQL mutation
      const newMessage = await client.graphql({
        query: createMessages,
        variables: { input },
      });

      // Show success message and reset form fields
      setSuccessMessage("Votre message a été envoyé avec succès !");
      setErrorMessage(null);
      reset(); // Reset form fields after successful submission

    } catch (err) {
      // Show error message in French
      setErrorMessage("Erreur lors de l'envoi du message : " + err.message);
      setSuccessMessage(null);
    }
  };

  return (
    <main>
      <Header />
      <div className="container mx-auto p-6">
        {/* Contact Information */}
        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Informations de contact</h2>
          <div className="space-y-4">
            <p className="text-lg"><strong>Téléphone:</strong></p>
            <p className="text-gray-600">+237 679 678 406</p>
            <p className="text-gray-600">+237 697 682 375</p>
            
            <p className="text-lg"><strong>Emplacement:</strong></p>
            <p className="text-gray-600">Douala</p>

            <p className="text-lg"><strong>Email:</strong></p>
            <p className="text-gray-600">haussinj@yahoo.fr</p>
          </div>
        </section>

        {/* Contact Form */}
        <section className="bg-white shadow-md rounded-lg p-6 h-[600px]">
          <h2 className="text-2xl font-semibold mb-4">Envoyez-nous un message</h2>

          {/* Display success or error messages */}
          {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-lg font-medium text-gray-700">Nom</label>
              <input 
                type="text" 
                id="name" 
                {...register("name", { required: true })}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.name && <p className="text-red-500 text-sm">le Nom est requis</p>}
            </div>

            <div>
              <label htmlFor="mail" className="block text-lg font-medium text-gray-700">Email</label>
              <input 
                type="email" 
                id="mail" 
                {...register("mail", { required: true })}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.mail ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.mail && <p className="text-red-500 text-sm">l'e-mail est requis</p>}
            </div>

            <div>
              <label htmlFor="message" className="block text-lg font-medium text-gray-700">Your Message</label>
              <textarea 
                id="message" 
                {...register("message", { required: true })}
                rows="4" 
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm">Le message est requis</p>}
            </div>

            <button 
              type="submit" 
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Envoyer 
            </button>
          </form>
        </section>
      </div>
      <Footers marginsTop={50} />
    </main>
  );
}
