import React from 'react';
import searchIcon from "../assets/img/Search.png";
import { Link } from 'react-router-dom';

export default function Header({ pageName, search, setSearch }) {
  const TopTabArray = ["Actualité", "nouveauté", "Portrait", "Chronique"];

  return (
    <header className='w-full flex flex-col items-center'>
      <Link to="/">
        <h1 className='logo'>Lalecturejecontribue</h1>
      </Link>
      <div className='w-11/12 border-2 border-solid border-slate-400 h-12 rounded-2xl flex justify-around items-center p-2 mt-5'>
        <img src={searchIcon} alt="Search" className='mr-2' />
        <input
          className='outline-none h-10 w-11/12 mb-1'
          placeholder='Rechercher un article'
          value={search} // Bind to search state
          onChange={(e) => setSearch(e.target.value)} // Update the state on change
        />
      </div>

      {/* Tabs */}
      <div className="w-full overflow-x-auto mt-4">
        <div className="flex h-12 rounded-2xl">
          {TopTabArray.map((item) => (
            <div key={item} className="px-3 py-2 whitespace-nowrap">
              <Link
                to="/Rubrique"
                state={{ pageName: item }}
                className={pageName === item ? "font-extrabold text-lg m-0" : "font-medium text-lg m-0 text-gray-600"}
              >
                {item}
              </Link>
            </div>
          ))}
          <div className="px-3 py-2 whitespace-nowrap">
            <Link
              to="/Agenda"
              className={pageName === "Agenda" ? "font-extrabold text-lg m-0" : "font-medium text-lg m-0 text-gray-600"}
            >
              Agenda
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}