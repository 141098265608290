import React from 'react'
import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Link } from 'react-router-dom';

Amplify.configure(awsExports);

export default function Login() {
    
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div>
            <p>Contact the admin to get permission</p>
            <Link to='/'>home</Link>
       
        </div>
        )}
   </Authenticator> 
  )
}
