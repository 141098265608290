import React, { useState, useEffect } from 'react';
import AddAgendaModal from '../components/AddAgendaModal';
import EditAgendaModal from '../components/EditAgendaModal';
import { listAgenda } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import Header from '../components/header';
import { deleteAgenda } from '../../graphql/mutations';

export default function EditAgenda() {
  const [agendas, setAgendas] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAgenda, setCurrentAgenda] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const client = generateClient();
 
 
 
   const getAgenda = async () => {
    setLoading(true);
    try {
      const listAgendaResponse = await client.graphql({
        query: listAgenda,
      });
      setAgendas(listAgendaResponse.data.listAgenda.items);
    } catch (error) {
      setError("Erreur lors de la récupération de l'agenda.");
      console.error("Erreur lors de la récupération de l'agenda :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAgenda();
  }, []);

  const handleDeleteAgenda = async (agendaId) => {
    if (window.confirm("Are you sure you want to delete this agenda?")) {
      try {
        await client.graphql({
          query: deleteAgenda,
          variables: { input: { id: agendaId } },
        });
        setAgendas((prevAgendas) =>
          prevAgendas.filter((agenda) => agenda.id !== agendaId)
        );
      } catch (error) {
        setError("Erreur lors de la suppression de l'agenda.");
        console.error("Erreur lors de la suppression de l'agenda :", error);
      }
    }
  };

  const handleEditClick = (agenda) => {
    setCurrentAgenda(agenda);
    setIsEditModalOpen(true);
  };

  return (
    <main className="p-4">
      <Header pageName="Agenda" isAdmin={true} />

      <div className="flex justify-center mt-4">
        <button
          className="bg-black text-white p-2 rounded hover:bg-gray-700 transition duration-300"
          onClick={() => setIsModalOpen(true)}
        >
          Ajouter un agenda
        </button>
      </div>

      {error && (
        <div className="text-red-500 text-center mt-4">
          {error}
        </div>
      )}

      {loading ? (
        <div className="text-center mt-4">
          <span className="loader"></span> {/* Add your spinner here */}
        </div>
      ) : (
        <div className="flex flex-col items-center mt-4">
          {agendas.map((agenda) => (
            <div key={agenda.id} className="bg-gray-200 p-4 rounded mb-4 w-80 shadow-lg">
              <h3 className="text-xl font-semibold">{agenda.name}</h3>
              <p>Date : {new Date(agenda.date).toLocaleDateString()}</p>
              <p>Note : {agenda.note}</p>
              <div className="flex space-x-2 mt-2">
                <button
                  className="bg-blue-500 text-white p-1 rounded hover:bg-blue-600 transition duration-300"
                  onClick={() => handleEditClick(agenda)}
                >
                  Modifier
                </button>
                <button
                  className="bg-red-500 text-white p-1 rounded hover:bg-red-600 transition duration-300"
                  onClick={() => handleDeleteAgenda(agenda.id)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <AddAgendaModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setAgendas={setAgendas}
        />
      )}

      {isEditModalOpen && currentAgenda && (
        <EditAgendaModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          agenda={currentAgenda}
          setAgendas={setAgendas}
        />
      )}
    </main>
  );
}
