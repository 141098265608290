import React, { useState, useEffect } from 'react';
import Footers from '../Components/Footers';
import Header from '../Components/Header';
import { listAgenda } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';

export default function AgendaPage() {
    const [Agenda, setAgenda] = useState([]);
    const [activeEvent, setActiveEvent] = useState(null); // State to manage active event
    const client = generateClient();

    // Fetch agenda data from API
    const getAgenda = async () => {
        try {
            const listAgendaResponse = await client.graphql({
                query: listAgenda,
            });
            setAgenda(listAgendaResponse.data.listAgenda.items);
        } catch (error) {
            console.error("Erreur lors de la récupération de l'agenda :", error);
        }
    };

    // Fetch the agenda data when the component mounts
    useEffect(() => {
        getAgenda();
    }, []);

    // Toggles the display of additional event details
    const toggleEventDetails = (eventId) => {
        setActiveEvent(activeEvent === eventId ? null : eventId);
    };

    return (
        <main className="w-full flex flex-col items-center min-h-[calc(100vh-100px)]"> {/* Updated min-height */}
            <Header />
            <h1 className="text-2xl font-bold mt-6 mb-4 ">Agenda</h1>
            <div className="w-11/12  max-w-3xl h-[300px]">
                {Agenda.length === 0 ? (
                    <p className="text-gray-600 text-center mt-6 mb-[350px]">Aucun événement disponible pour le moment.</p>
                ) : (
                    Agenda.map(event => (
                        <div key={event.id} className="mb-4 p-4 bg-white shadow-md rounded-md">
                            <div onClick={() => toggleEventDetails(event.id)} className="cursor-pointer">
                                <h2 className="text-xl font-semibold">{event.name}</h2>
                                <p className="text-gray-600 mt-2">Date de l'événement : {new Date(event.date).toLocaleDateString()}</p>
                            </div>
                            {/* Conditionally render extra notes when the event is clicked */}
                            {activeEvent === event.id && (
                                <div className="mt-4 p-4 bg-gray-100 rounded-md">
                                    <h3 className="text-lg font-bold">Notes :</h3>
                                    <p>{event.description}</p> {/* Updated to use description */}
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
            <Footers />
        </main>
    );
}